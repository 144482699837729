// $box-shadow: 5px 5px 10px rgba(22, 27, 29, 0.23);

// $box-shadow-new: 0px 1.1px 5.6px -34px rgba(0, 0, 0, 0.045),
//   0px 3.1px 15.4px -34px rgba(0, 0, 0, 0.065),
//   0px 7.5px 37.1px -34px rgba(0, 0, 0, 0.085),
//   0px 25px 123px -34px rgba(0, 0, 0, 0.13);

$box-shadow: 0px 9px 27px -4px rgba(0, 0, 0, 0.13);

.admin-item-menu {
  ion-item::part(native) {
    --padding-start: 0px;
  }
}

.label-accordion-menu {
  padding-left: 16px;
}

.header-menu {
  box-shadow: none;
}

.header-mid-base {
  background: var(--ion-color-lobos-primary);

  ion-row {
    height: 100%;
  }

  &.transparent {
    background: transparent;
  }
}

.header-mid-base-logo {
  background: var(--ion-color-lobos-primary);

  &.radius {
    border-top-right-radius: 32px;
    height: 100%;
    background: white;
  }
}

.header-img {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-mid-radius {
  height: 48px;
  background: #fff;
  border-top-right-radius: 32px;
  width: 211px;
  word-wrap: break-word;
  &.transparent {
    background: transparent;
  }
}

.header-left-buttons {
  background: #fff;

  &.transparent {
    background: transparent;

    ion-icon {
      color: white;
    }
  }
}

.header-right-buttons {
  background: #fff;

  &.transparent {
    background: transparent;
  }
}
.header-right-buttons-base {
  padding-inline: 16px;
  color: white;
  background: var(--ion-color-lobos-primary);
  border-bottom-left-radius: 20px;

  &.transparent {
    background: transparent;
  }
}

.header-buttons {
  height: 48px;
}

.container-page {
  --padding-start: 16px;
  --padding-end: 16px;
}
.menu-border {
  border-right: 1px solid #ffffff;
}

.menu-background {
  background: var(--ion-color-cabildo);
  --background: var(--ion-color-cabildo);

  .top-left-radius {
    background: var(--ion-color-lobos-primary);
    border-top-left-radius: 32px;
    padding-top: 16px;
  }

  .blue {
    border-bottom-right-radius: 32px;
    background: var(--ion-color-lobos-primary);
  }
}

.menu-lists {
  background: var(--ion-color-lobos-primary);
  --background: var(--ion-color-lobos-primary);

  .top-left-radius {
    background: var(--ion-color-lobos-primary);
    border-top-left-radius: 32px;
  }
}

.bottom-right-radius {
  background: var(--ion-color-cabildo);
  border-bottom-right-radius: 32px;
}

.background-base-blue {
  background: var(--ion-color-lobos-primary);
  --background: var(--ion-color-lobos-primary);
}

.background-base-white {
  background: white;
  --background: white;
}

.menu-logo {
  &.header {
    background: transparent;
  }

  &.logo {
    padding: 8px 32px;
    background: white;
    border-bottom-right-radius: 32px;
  }

  &.right-radius {
    background: var(--ion-color-lobos-primary);
    border-top-left-radius: 32px;
  }
}

.cabildo-background-base {
  background: var(--ion-color-lobos-primary);
  --background: var(--ion-color-lobos-primary);
}

.cabildo-logo-container {
  background: var(--ion-color-cabildo);
  border-top-left-radius: 32px;

  &.base {
    border-radius: 0;
    height: auto;
  }

  &.radius {
    background: var(--ion-color-lobos-primary);
    border-top-left-radius: 0;
    border-bottom-right-radius: 32px;
    height: 56px;
  }
}

.log-menu-div {
  background: var(--ion-color-lobos-tertiary);
  --background: var(--ion-color-lobos-text-white);
  border-top-left-radius: 32px;
  color: white;
}

.text-blue {
  color: var(--ion-color-lobos-blue);
  --color: var(--ion-color-lobos-blue);
}
.text-white {
  color: var(--ion-color-lobos-text-white);
  --color: var(--ion-color-lobos-text-white);
}
.text-modal-visitor {
  color: #ffffff !important;
  --color: #ffffff !important;
}

.item-menu-toggle {
  height: 40px;
}
.menu-background2 {
  background: var(--ion-color-lobos-text-white);
  --background: var(--ion-color-lobos-blue);
  color: var(--ion-color-lobos-text-white);
}

.menu-item-background {
  --background: var(--ion-color-lobos-blue);
  --color: var(--ion-color-lobos-text-white);
}

.ion-icon-inside-menu-item {
  color: var(--ion-color-lobos-text-white);
}

.background-body-color {
  border-top-right-radius: 32px;
  background-color: var(--ion-color-lobos-primary);
}

.background-transparent {
  background-color: transparent;
}

.background-white {
  background-color: white;
}

.background-item-white {
  --background: var(--ion-color-lobos-text-white);
}

.full-height {
  height: 100%;
}

.mnxoheader-content-container-base {
  padding: 32px 16px 0px 16px;
  height: 150px;
  width: 100%;
  background: var(--ion-color-lobos-primary);
}

.mnxoheader-content-container {
  background: #fff;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  padding: 32px 16px 0px 16px;

  &.modal {
    border-top-right-radius: 0px;
  }

  &.home {
    background: white;
    border-bottom-left-radius: 0px;
  }
}

.sticker {
  display: flex;
  text-align: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  text-align: end;
  justify-content: end;
}

.description-text {
  font-size: 0.875rem;
  color: white;
  padding: 0px 16px 0px 16px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.text-black {
  color: var(--ion-color-lobos-text-black);
}

.shift-selector-tittle {
  color: var(--ion-color-lobos-text-black);
  margin-bottom: 16px;
}

.text-secondary {
  color: var(--ion-color-lobos-secondary);
}
.text-primary {
  font-weight: 600;
  color: var(--ion-color-lobos-primary);
}
.visitor-card-name {
  color: var(--ion-color-lobos-primary);
}

.text-main-visitor {
  font-weight: 600;
  color: var(--ion-color-lobos-primary);
}

.visiting-shift-date {
  font-size: 3.125rem;
}

.padding-top {
  padding-top: 32px;
}

.visiting-shift-month {
  font-size: 1.563rem;
  font-weight: 600;
}

.flex-center {
  display: flex;
  text-align: center;
  justify-content: center;
}

.qr-container {
  background-color: #ffffff;
  position: relative;
  width: 70%;
  padding-top: 70%; /* Relación de aspecto 1:1 (100% de la anchura) */
  margin-top: 16px;
  border: 1px solid var(--ion-color-lobos-text-grey);
  border-radius: 16px;
  box-shadow: $box-shadow;
  margin-bottom: 10px;
}

.scan-container {
  padding-top: 32px;
}

.qr-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 16px;
  div {
    video {
      width: auto !important;
    }
    svg {
      border: none !important;
      path {
        stroke: var(--ion-color-lobos-primary);
      }
    }
  }
}
.video-container {
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio (100% de la altura del contenedor) */
}
.video-container video {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%; /* Asegura que el video llene el contenedor */
  object-fit: cover;
}

.margin-bottom {
  margin-bottom: 32px;
}

.validation-container {
  min-height: 150px;
  width: 100%;
  background: var(--ion-color-lobos-primary);
}

.label-chip {
  color: var(--ion-color-lobos-text-white);
  background-color: var(--ion-color-lobos-tertiary);
  padding: 10px 16px;
  border-radius: 20px;
  z-index: 1;
  position: relative;
  bottom: -20px;
}

.search-bar-sticker {
  color: var(--ion-color-lobos-text-white);
  background: var(--ion-color-lobos-tertiary);
  border-radius: 20px;
  z-index: 1;
}

a {
  cursor: pointer;
}

.selected-date {
  p {
    font-weight: 400;
    color: var(--ion-color-lobos-secondary);
    text-transform: uppercase;
  }
}

.transparent {
  background: transparent;
}

.modal_third_view_content {
  --padding-start: 32px;
  --padding-top: 16px;
  --padding-end: 32px;
  --padding-bottom: 16px;
}

.MNXOHighlightDate {
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-transform: uppercase;

  .day-format {
    font-size: 3.125rem;
    height: 55px;
  }

  .month-format {
    font-size: 1.563rem;
  }
}
/* Consultas de medios para hacerlo responsive */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .MNXOHighlightDate {
    .day-format {
      font-size: 1.25rem;
      height: 28px;
    }

    .month-format {
      font-size: 0.5rem;
    }
  }
}
/* Consultas de medios para hacerlo responsive */
@media only screen and (max-width: 767px) {
  .MNXOHighlightDate {
    .day-format {
      font-size: 2.5rem;
      height: 45px;
    }

    .month-format {
      font-size: 1.25rem;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .MNXOHighlightDate {
    .day-format {
      font-size: 2.75rem;
      height: 50px;
    }

    .month-format {
      font-size: 1.438rem;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .MNXOHighlightDate {
    .day-format {
      font-size: 3rem;
      height: 55px;
    }

    .month-format {
      font-size: 1.5rem;
    }
  }
}

.header {
  --background: #fff;
  --color: rgb(17, 17, 17);
  --box-shadow: none;
  --min-height: 48;
  width: 100%;
  &.transparent {
    --background: transparent;
    backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 0, 0.13);
  }
}

.full-height {
  height: 100vh;
}

ion-toolbar.transparent {
  --background: var(--ion-color-lobos-text-white);

  /*   --color: white;

  --border-color: #f24aec;
  --border-width: 4px 0;
  --border-style: double;

  --min-height: 80px;
  --padding-top: 20px;
  --padding-bottom: 20px; */
}

.lobos-background::part(background) {
  background: none;

  background: rgba(#008293, 0.15);

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(var(--ion-color-lobos-secondary), 0.5) 0%,
    rgba(var(--ion-color-lobos-secondary), 0.1) 50%,
    rgba(var(--ion-color-lobos-secondary), 0.5) 100%
  );
  //--background: rgba(var(--ion-color-primary-rgb), 0.1);
  //--background: none;
  //padding: 0;: 0;
}

.lobos-background::part(scroll) {
  color: var(--ion-color-lobos-primary-contrast);
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}

.lobos-background {
  //--background: none;

  background: url("../../public/assets/result.jpg") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: 25% 50%;
  z-index: 1;
  overflow: hidden;
  &.info-interest {
    background-position: 50% 50%;
  }
}

.container {
  padding: 32px 16px;
  height: 300px;
  width: 100%;
  background: var(--ion-color-lobos-secondary);
}

.selector {
  background-color: #ffffff;
  backdrop-filter: saturate(180%) blur(10px);

  box-shadow: $box-shadow;
}

//---------------------------------LOGIN FORM --------------------------------//

.login-form {
  background-color: var(--ion-color-lobos-text-white);
  border-radius: 16px;
  z-index: 100;
}

// -------------------------------- CUSTOM DATE --------------------------------

$disabled: #777777;
$highVisitingShiftAvailableCapacity: #59cc3f;
$mediumVisitingShiftAvailableCapacity: #d28110;
$lowVisitingShiftAvailableCapacity: #ff3407;

.h3 {
  padding-bottom: 32px;
}

.visiting-shifts-indicators {
  gap: 5px;
}

.date {
  font-weight: 600;
  color: var(--ion-color-lobos-text-black);
  &.number {
    color: var(--ion-color-lobos-black);
  }
}

.selected-date {
  font-weight: 700;
  color: var(--ion-color-lobos-text-white);
}

ion-chip {
  &.high-available-capacity {
    --color: $highVisitingShiftAvailableCapacity;
    --background: rgba(119, 119, 119, 0.3);
  }

  &.medium-available-capacity {
    --color: $mediumVisitingShiftAvailableCapacity;
    --background: rgba(119, 119, 119, 0.3);
  }

  &.low-available-capacity {
    --color: $lowVisitingShiftAvailableCapacity;
    --background: rgba(119, 119, 119, 0.3);
  }

  &.full-capacity {
    --color: $disabled;
    --background: rgba(119, 119, 119, 0.3);
  }
}

.visiting-shift-capacity {
  border-radius: 15px;
  max-width: 10px;
  height: 10px;

  &.high-available-capacity {
    background-color: $highVisitingShiftAvailableCapacity;
  }

  &.medium-available-capacity {
    background-color: $mediumVisitingShiftAvailableCapacity;
  }

  &.low-available-capacity {
    background-color: $lowVisitingShiftAvailableCapacity;
  }

  &.full-capacity {
    background-color: $disabled;
    opacity: 0.3;
  }
}

.visiting-shift-capacity-text {
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 24px;
  margin-top: 4px;
  color: white;

  &.high-available-capacity {
    color: $highVisitingShiftAvailableCapacity;
  }

  &.medium-available-capacity {
    color: $mediumVisitingShiftAvailableCapacity;
  }

  &.low-available-capacity {
    color: $lowVisitingShiftAvailableCapacity;
  }

  &.full-capacity {
    color: $disabled;
  }
}

// -------------------------------- APPLICATION --------------------------------

.container {
  padding: 32px 16px;
  height: 0px;
  width: 100%;
  background: var(--ion-color-lobos-secondary);
}

.calendar-container {
  min-height: 150px;
  width: 100%;
  background: var(--ion-color-lobos-primary);
  border-radius: 32px;
}

.calendar-container-base {
  min-height: 150px;
  width: 100%;
  background: var(--ion-color-lobos-primary);
}

.row-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.calendar-content-container {
  background: #fff;
  border-top-right-radius: 32px;
  padding: 32px 16px 0px 16px;
}

.visiting-shifts-container {
  width: 100%;
  background: white;
  border-bottom-left-radius: 32px;
  min-height: auto;
  padding: 32px 16px 32px 16px;
  height: auto;
  h2,
  h3,
  h4 {
    padding-inline: 0;
  }
}

.visiting-shifts-container-base {
  background: var(--ion-color-lobos-primary);
}

.available-capacity {
  p {
    font-size: 0.875rem;
  }

  &.title {
    p {
      font-weight: 700;
    }
  }
}

.calendar-item {
  background-color: transparent;
  --background: transparent;
  padding: 16px 0 16px 0;
  box-shadow: none;
  border: none;
}

.selected-calendar-item {
  --background: var(--ion-color-lobos-primary);
  box-shadow: none;
  border: none;
  padding: 16px 0 16px 0;
  border-radius: 16px;
  margin-inline: 10%;
}

.visitor-counter-container {
  width: 100%;
  padding: 20px;
  border-top-right-radius: 32px;
  background: var(--ion-color-lobos-primary);
  padding-inline: 16px;
  h2,
  h3,
  h4 {
    padding-inline: 0;
  }
}

.card {
  background-color: transparent;
  box-shadow: none;

  &.selected {
    background-color: var(--ion-color-lobos-secondary);
  }
}

.card-content {
  padding: 0;
}

.visitor-counter-card {
  --background: transparent;
  background: transparent;
  box-shadow: none;
}

.swiper-container {
  background: #fff;
  // box-shadow: $box-shadow;
  border-radius: 16px;
  z-index: 1;
  position: relative;
  padding-top: 20px;
  bottom: 0px;
}

.visitor-form-content {
  background: var(--ion-color-lobos-primary);
  padding: 50px;
}

.visitors-list {
  background: var(--ion-color-lobos-primary);
}

.visitors-swiper-container {
  border-radius: 0;
  background: var(--ion-color-lobos-primary);
}

.visiting-shift-time {
  color: var(--ion-color-lobos-text-black);
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
}

.shift-selector {
  display: flex;
  flex-direction: column;
  padding: 0px 0 20px 0;
}

.visitor-counter-content {
  background: white;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 16px;
}

.availability {
  background: #150448;
  position: relative;
  padding-inline: 16px;
  border-radius: 0px 8px 0px 8px;
  top: 16px;
  right: 0%;
  z-index: 1;
  width: 300px;
  height: 32px;
}

.availability-content {
  color: white;
  height: 24px;
}

.divisory-line {
  height: 150px;

  border-left: 3px solid var(--ion-color-lobos-primary);

  &.white {
    border-radius: 10px;
    height: 75px;
    margin-inline: 30px;
    border: 2px solid var(--ion-color-lobos-text-white);
  }

  &.primary {
    border-radius: 10px;
    height: 75px;
    margin-inline: 16px;
    border: 2px solid var(--ion-color-lobos-primary);
  }
}

.counter-visitor-icon {
  color: var(--ion-color-lobos-primary);
}

.counter-buttons {
  width: 110px;
  // border: 1px solid var(--ion-color-lobos-text-black);
  color: var(--ion-color-lobos-primary);
  background: var(--ion-color-lobos-light-background);
  border-radius: 32px;
}

.counter-button {
  color: var(--ion-color-lobos-text-black);
  --background: #fff;
  --box-shadow: 0;
  margin: 5px;
  --border-radius: 32px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.visitor-form {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 16px;
  background: white;
  border-radius: 16px;
  gap: 5px;
  padding-bottom: 18px;
  margin-bottom: 0px;
}

.visitor-details {
  border-radius: 16px;
  background: white;
  padding-bottom: 16px;
  margin-bottom: 16px;

  ion-item {
    height: 34px;
  }
}

.application-third-view-content-base {
  background: white;
}

.application-third-view-content {
  background: var(--ion-color-lobos-primary);
  border-bottom-left-radius: 32px;
  padding-bottom: 16px;
}

.application-third-view-confirmation-email {
  background: var(--ion-color-lobos-primary);
}

.application-third-view-confirmation-email-radius {
  background: white;
  border-top-right-radius: 32px;
}

.text-visitor-form-header {
  font-weight: 600;
  color: var(--ion-color-lobos-primary);
}

.icon-inside-item {
  ion-icon {
    color: var(--ion-color-lobos-text-blue);
    --color: var(--ion-color-lobos-text-blue);
  }
}

#faq-label {
  font-size: 1.5rem;
  word-wrap: break-word;
  white-space: normal;
  margin-bottom: 16px;
  margin-top: 16px;
}

.faq-answer {
  font-size: 1.125rem;
  margin-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
}

.selected-visiting-shift {
  --background: #fff;
  border: 2px solid var(--ion-color-lobos-secondary);
  ion-label {
    color: var(--ion-color-lobos-text-black);
  }
  ion-note {
    color: var(--ion-color-lobos-text-grey);
  }
}

.ion-valid {
  --highlight-color-valid: var(--ion-color-lobos-secondary);
  --highlight-color-focused: var(--ion-color-lobos-secondary);
}

.secondary-button {
  --background: none;
  --color: var(--ion-color-lobos-secondary);
}
.secondary-button-blue-background {
  --background: none;
  --color: var(--ion-color-lobos-text-white);
}
.menu-icons {
  color: var(--ion-color-lobos-text-white);
}

.menu-icons-secondary {
  color: var(--ion-color-lobos-text-white);
}
.ion-input {
  --highlight-color-valid: var(--ion-color-lobos-secondary);
  --highlight-color-focused: var(--ion-color-lobos-secondary);
}
.text-area {
  padding-left: 16px;
  padding-right: 16px;
}
.counter-form {
  color: var(--ion-color-lobos-secondary);
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 600;
  margin-bottom: 32px;
}

.application-form {
  color: var(--ion-color-lobos-secondary);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 32px;
}
.application-card-title {
  color: white;
  padding-bottom: 16px;
}

.visitor-application-card {
  border: none;
  margin-bottom: 16px;
  border-radius: 16px;
  // box-shadow: 0px 5px 10px rgba(22, 27, 29, 0.23);
  box-shadow: $box-shadow;
}
.visitor-date-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visitor-date-col {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .visitor-date-row {
    margin-top: 25%;
  }
}

.application-card {
  margin-bottom: 20px;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin-bottom: 16px;

  ion-item::part(native) {
    background: transparent;
    padding-inline: 0px;
    --inner-padding-end: 0;
  }
}

.icon-edit {
  position: absolute;
  font-size: 1rem;
  transform: rotate(45deg);
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .toggle-text {
    font-size: 15px; /* Ajusta el tamaño del texto según sea necesario */
  }
}

@media only screen and (max-width: 375px) {
  .toggle-text {
    font-size: 12px;
  }
}

.animated-icon-swiper {
  position: absolute;
  font-size: 1rem;
  width: 180px;
  height: 90px;
  bottom: 0;
  right: 0;
}
.card-visitor-application {
  border: 1px solid var(--ion-color-lobos-text-grey);
  border-radius: 16px;
  box-shadow: 0px 5px 10px rgba(22, 27, 29, 0.23);
}

.section-application-card-visitor,
.section-application-card-date {
  border: 1px solid var(--ion-color-lobos-text-grey);
  border-radius: 16px;
  box-shadow: $box-shadow;
  margin-bottom: 10px;
}
.section-application-card-visitor {
  border-right: var(--ion-color-lobos-text-grey) 1px dashed;
}
.section-application-card-date {
  border-left: none;
  padding: 16px;
  box-shadow: $box-shadow;
  text-align: center;
}
.buttons-final-view {
  margin-top: 30%;
}
// -------------------------------- AUTHORIZATIONS --------------------------------

.autohorization-form {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 32px;

  ion-input.recovery {
    --color: white;
    --border-color: white;
    --highlight-color-valid: var(--ion-color-lobos-secondary);
    --highlight-color-focused: var(--ion-color-lobos-secondary);

    font-weight: 400;
  }

  .counter.sc-ion-input-md {
    --color: white;
  }
}

.container {
  background: transparent;
  --padding-top: 32px;
  --padding-start: 16px;
  --padding-end: 16px;
}

.non-container {
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.authorization-card {
  background: transparent;
  box-shadow: $box-shadow;
  border-radius: 16px;
}

.icon-information {
  margin-left: 8px;
  color: var(--ion-color-lobos-text-black);
}
.error {
  color: red;

  &.text {
    padding-inline: 16px;
    font-size: 0.75rem;
  }
}

.section-authorization-visitor,
.section-authorization-date {
  border: 1px solid var(--ion-color-lobos-text-grey);
  border-radius: 16px;
  box-shadow: $box-shadow;
  margin-bottom: 10px;
}
.section-authorization-visitor {
  border-right: var(--ion-color-lobos-text-grey) 1px dashed;
}
.section-authorization-date {
  border-left: none;
  padding: 16px;
  box-shadow: $box-shadow;
}

.authorization-card-header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.day-authorization-card-header,
.shift-authorization-card-header,
.openingHours-authorization-card-header {
  color: var(--ion-color-lobos-primary);
}
.shift-authorization-card-header {
  font-size: 1.75rem;

  font-weight: 600;
}
.openingHours-authorization-card-header {
  font-size: 1.25rem;
}

.codeQR {
  padding: 20px;
}
.name-shift-authorizatin-card {
  margin-top: 16px;
  border-top: 1px solid var(--ion-color-lobos-primary);
  color: var(--ion-color-lobos-primary);
  font-size: 1.35rem;
  .ion-text-sm- {
    font-size: 0.035rem !important;
  }
}
.main-visitor-application-card {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.name-shift-auth-card-text {
  margin-top: 16px;
}

// -------------------------------- MENU --------------------------------

#icons {
  margin-right: 10px;
}

#profile {
  --inner-padding-top: 20px;
  --inner-padding-bottom: 20px;
}

#item-menu {
  --color-hover: var(--ion-color-lobos-secondary);

  --color-activated: var(--ion-color-lobos-secondary);
}

#text {
  padding-inline: 20px;
}

.text-grey {
  color: var(--ion-color-lobos-text-grey);
}

.active {
  color: var(
    --ion-color-lobos-secondary
  ); /* Color de texto para el elemento activo */
  font-weight: bold; /* Estilo de fuente para el elemento activo */
  --color-focused: none;
  --background-hover: var(--ion-color-lobos-text-white);
}

ion-menu::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

// -------------------------------- MANNIX --------------------------------

$gradient-background: var(--ion-color-lobos-secondary)
  linear-gradient(
    45deg,
    var(--ion-color-lobos-secondary) 0%,
    var(--ion-color-lobos-primary) 100%
  );

ion-content::part(background) {
  --background: transparent;
}

// -------------------------------- INCIDENTS --------------------------------

.error-message {
  color: red;
}
.incidents-list-message {
  text-align: center;
  color: var(--ion-color-lobos-text-white);
}
.incident-card {
  border: none;
  margin-bottom: 16px;
  border-radius: 16px;
  box-shadow: $box-shadow;
}

.incident-title-section {
  padding: 8px;
  margin-top: 16px;
  color: var(--ion-color-lobos-primary);
}

.incident-title-section h2 {
  color: var(--ion-color-lobos-primary);
  font-weight: 700;
}
ion-thumbnail {
  --size: 140px;
  --border-radius: 14px;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-label-incident-form {
  color: #777;
  font-size: 1.125rem;
}

// -------------------------------- STADISTICS --------------------------------

.progress-bar {
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px;
}

.progress {
  height: 20px;
  background-color: #0064aa;
  border-radius: 5px;
  margin-bottom: 5px;
}

.progress-bar p {
  margin: 0;
  color: #555;
  font-size: 0.875rem;
  line-height: 1.5;
}

// -------------------------------- HOME --------------------------------

.home-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero {
  padding: 20px;
  text-align: center;
}

.hero h1 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.125rem;
  color: #888;
}

//media

@media (max-width: 349px) {
  .mnxoheader-content-container {
    padding-top: 8px;
  }
}

// -------------------------------- MENU --------------------------------
.pagination {
  margin-top: 16px;
}

.pagination-link {
  background-color: white;
  color: var(--ion-color-lobos-primary);
}

.authorizaion-page {
  background: var(--ion-color-lobos-primary);
}
//------------------PAGINATION BULLETS------------------------

.element-bullet {
  padding: 10px;
  margin: 6px;
  cursor: pointer;
  transition: transform 0.3s;
  background-color: var(--ion-color-lobos-tertiary);
  border-radius: 50px;
  max-width: 15px;
  height: 15px;
  -webkit-transition: background-color 0.5s, max-width 0.5s;
  -o-transition: background-color 0.5s, max-width 0.5s;
  transition: background-color 0.5s, max-width 0.5s;
  -webkit-transition-delay: 0.5s, 0s;
  -o-transition-delay: 0.5s, 0s;
  transition-delay: 0.5s, 0s;
}
.element-bullet-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.element-bullet.active {
  padding: 10px;
  max-width: 35px;
  height: 15px;
  background-color: var(--ion-color-lobos-secondary);
}

.pagination-bullets-container {
  margin-top: 16px;
}

.disabled {
  color: red;
  opacity: 0.5;
}

// -------------------------------- FOOTER --------------------------------

.MNXOFooter {
  padding: 0px 16px;
  box-shadow: none;
  background-color: var(--ion-color-lobos-primary);

  .margin {
    margin-left: 8px;
  }

  ion-toolbar {
    --background: transparent;
  }
}

.MNXOFooter-button {
  margin-top: 8px;
  margin-bottom: 4px;
}
// -------------------------------- VISITOR MODAL --------------------------------

.visitor-modal {
  min-height: 50px;
  width: 100%;
  background: var(--ion-color-lobos-primary);
}

// -------------------------------- APPLICATION MODAL --------------------------------
.modal-container {
  --min-height: 90%;
  z-index: 1001;
}

.close-modal-icon {
  color: var(--ion-color-lobos-text-white);
  font-size: 1.625rem;
  cursor: pointer;
  font-weight: 700;
}
.application-modal-content {
  background: var(--ion-color-lobos-primary);
  width: 100%;
}

.modal-toolbar {
  --min-height: auto;
}

.modal-row {
  height: auto;
}

.modal-pagination {
  padding-left: 16px;
}

.modal-buttons-container {
  padding-right: 16px;
  border-top-left-radius: 32px;
  background: var(--ion-color-lobos-tertiary);
  padding-top: 8px;
  padding-bottom: 8px;
}

.MNXOModalFooter {
  z-index: auto;
  padding: 0px 16px 0px;
  background: var(--ion-color-lobos-primary);
  box-shadow: none;

  &.no-padding {
    padding: 0px;
  }

  .margin {
    margin-left: 8px;
  }

  ion-toolbar {
    z-index: auto;

    --background: transparent;
  }
}

.visitor-type-text {
  padding-left: 4px;
}
.MNXOIntroFooter {
  background: white;
  box-shadow: none;

  .margin {
    margin-left: 8px;
  }

  ion-toolbar {
    --background: transparent;
  }
}

// -------------------------------- SWIPER --------------------------------
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: 0.5vh;
  height: 0.5vh;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-lobos-primary, var(--ion-color-lobos-primary));
  font-weight: 700;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled & {
    display: none !important;
  }
  svg {
    width: 80%;
    height: 80%;
    object-fit: contain;
    transform-origin: center;
  }
  .swiper-rtl & svg {
    transform: rotate(180deg);
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset);
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset);
  left: auto;
}
.swiper-button-lock {
  display: none;
}
/* Navigation font start */
.swiper-button-prev,
.swiper-button-next {
  &:after {
    font-family: swiper-icons;
    font-size: 0.05rem;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    content: "prev";
  }
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    content: "next";
  }
  right: var(--swiper-navigation-sides-offset, 10px);
  left: auto;
}

.icon-swiper-container {
  margin-top: 32px;
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--ion-color-lobos-blue, var(--ion-color-lobos-blue));
    top: 50%;
    &:after {
      font-family: swiper-icons;
      font-size: 0.688rem;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
  }
}
// @media (max-width: 767px) {
//   .icon-swiper-container {
//     .swiper-button-prev,
//     .swiper-button-next {
//       top: 70%;
//     }
//   }
// }

// -------------------------------- COMPONENT PAGE --------------------------------
.component-page-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.component-page-titles h2 {
  background-color: var(--ion-color-lobos-primary);
  padding: 5px;
}

.error-page-content {
  background: var(--ion-color-lobos-text-white);
  border-radius: 16px;
  box-shadow: $box-shadow;
}
// -------------------------------- CALENDAR TITLE --------------------------------

.calendar-title {
  background: #fff;
  border-radius: 15px 15px 0pc 0px;
  z-index: 2;
  position: relative;
  padding-top: 20px;
  box-shadow: none;
  bottom: -70px;
}

.current-date {
  padding: 16px 16px 16px 0;

  ion-label > h3 {
    font-size: 1.125rem;
  }
}

.calendar-month {
  color: var(--ion-color-lobos-text-black);
}

// -------------------------------- VALIDATION PAGE --------------------------------

.auth-card-test-container {
  justify-content: center;
  margin: 16px;
}

ion-toggle::part(track) {
  height: 20px;
  width: 50px;

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
}

div > p > a {
  color: var(--ion-color-lobos-secondary);
}
// -------------------------------- SPLASH --------------------------------
#splash-content {
  height: 100vh;
}

.grid-splash {
  height: 100%;
  width: 100%;
  padding: 0;
}

.row-splash {
  &:first-of-type {
    height: 50vh;
    background-color: #94bdc9;
    ion-img {
      position: absolute;
      bottom: -15vh;
      width: 70%;
      left: 15%;

      &:nth-of-type(1) {
        z-index: 6;
        opacity: 0;
        animation: fade-in 5s ease-in-out 1 forwards;
        animation-delay: 2s;
      }

      &:nth-of-type(2) {
        animation: fade-out 5s ease-out 1 forwards;
      }
    }
  }

  &:nth-of-type(2) {
    height: 0;
  }

  &:last-of-type {
    height: 50vh;

    ion-col {
      height: 100%;
    }

    ion-img {
      position: absolute;
      bottom: 0;
      width: 70%;
      left: 15%;
      height: 25vh;
    }
  }
}

.waves {
  display: block;
  width: 100%;
  height: 60px;
  max-height: 60px;
  margin: 0;
  z-index: 5;
  top: -60px;
  position: absolute;
  left: 0px;
  float: left;
}

.parallax1 > use {
  animation: move-forever1 10s linear infinite;
  fill: rgba(#ffffff, 0.4);

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

.parallax2 > use {
  animation: move-forever2 8s linear infinite;
  fill: rgba(#ffffff, 0.6);

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

.parallax3 > use {
  animation: move-forever3 6s linear infinite;
  fill: rgba(#ffffff, 0.8);

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

.parallax4 > use {
  animation: move-forever4 4s linear infinite;
  fill: rgba(#ffffff, 1);

  &:nth-child(1) {
    animation-delay: -2s;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

// -------------------------------- WELCOME--------------------------------

#welcome-title {
  color: #ffffff;
}
#welcome-p-title {
  color: #0064aa;
}

.welcome-svg {
  height: 101%;
}
.bottom-info {
  padding: 16px;
  position: relative;
  text-align: center;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
}
.bottom-info-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

//------------------------------Gallery --------------------------------
#gallery {
  margin: 0;
  padding: 0px 16px;
}

.gallery-slides {
  width: 100%;
  position: relative;

  .swiper-slide {
    width: 100%;
    border-radius: 16px;

    ion-img::part(image) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  /* Estilos para dispositivos móviles */
  @media only screen and (max-width: 767px) {
    height: 22vh;

    .swiper-slide {
      height: 18vh;
    }
  }

  /* Estilos para tablet y escritorio */
  @media only screen and (min-width: 768px) {
    height: 32vh;

    .swiper-slide {
      height: 28vh;
    }
  }

  /* Ajustes adicionales para pantallas más grandes si es necesario */
  @media only screen and (min-width: 992px) {
    height: 42vh;

    .swiper-slide {
      height: 38vh;
    }
  }
}

.modal-container {
  z-index: 1001;
  --min-height: 90%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
  font-weight: bolder;
  cursor: pointer;
  transition: color 0.3s ease;
}
.close-button:hover {
  color: rgba(255, 255, 255, 1);
}

//------------------------------SWIPER GALLERY --------------------------------

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-pagination-disabled > .swiper-pagination,
.swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 8px;
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 8px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 8px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet:only-child {
  display: none !important;
}
.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 20%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-vertical
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.swiper-horizontal.swiper-rtl
  > .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}
/* Fraction */
.swiper-pagination-fraction {
  color: var(--swiper-pagination-fraction-color, inherit);
}
/* Progress */
.swiper-pagination-progressbar {
  background: var(
    --swiper-pagination-progressbar-bg-color,
    rgba(0, 0, 0, 0.25)
  );
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: var(--swiper-pagination-progressbar-size, 4px);
  left: 0;
  top: 0;
}
.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-horizontal
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
  width: var(--swiper-pagination-progressbar-size, 4px);
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-lock {
  display: none;
}
// -------------------------------- MASONRY --------------------------------

.masonry-img {
  width: 100%;
  border-radius: 16px;
}
.masonry-container {
  padding: 16px;
  left: 50%;
  width: 100%;
}
.masonry-show-button {
  width: 100%;
  margin-top: 0px;
  --background: transparent;
  --color: var(--ion-color-lobos-blue);
  border: 6px solid var(--ion-color-lobos-blue);
  --border-width: 0px;
  border-radius: 16px;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    background-color: var(--ion-color-lobos-blue);
    color: var(--ion-color-lobos-text-white);
  }
}
// -------------------------------- ICON SWIPER --------------------------------
.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 2px solid var(--ion-color-lobos-blue);
  border-radius: 50%;
  margin: 0 auto; /* Centra horizontalmente */
}
.icon-circle:hover {
  background: var(--ion-color-lobos-blue);
  color: var(--ion-color-lobos-text-white);
}
.icon-category {
  text-align: center;
  font-size: 2rem;
  color: var(--ion-color-lobos-blue);
}
.clicked {
  color: var(--ion-color-lobos-text-white);

  background: var(--ion-color-lobos-blue);
}
.icon-category:hover {
  color: var(--ion-color-lobos-text-white);
}
.icon-label {
  font-size: 0.75rem;
  color: var(--ion-color-lobos-blue);
  padding-top: 12px;
  text-align: center;
}
.icon-label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.icon-wrapper {
  text-align: center;
  justify-content: center;
  padding: 16px;
}

//--------------------------------- Home Page --------------------------------
.interest-container {
  margin: 16px;
}
.interest-title {
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.13);
  position: absolute;
}
.interest-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -200px;
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.13), transparent);
  z-index: 1;
}

.poi-score {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ion-item::part(native) {
    padding: 0;
    --inner-padding-end: 0px;
  }
}

.poi-tabs {
  margin-bottom: 200px;
}

.interest-card {
  margin: 16px;
  background: transparent;
  border: none;
  border-radius: 16px;
  box-shadow: $box-shadow;
  cursor: pointer;
  border: 1px solid var(--ion-color-lobos-blue);
  h1 {
    font-size: 1rem;
    font-weight: 700;
    color: var(--ion-color-lobos-blue);
    justify-content: start;
  }
}
.interest-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.like-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 1.5rem;
  color: var(--ion-color-lobos-blue);
  cursor: pointer;
}

.star-icon {
  font-size: 1rem;
  padding: 2px;
  color: var(--ion-color-lobos-blue);
  cursor: pointer;
}
.location-text {
  align-items: center;
  color: #00cfe3;
  font-size: 1.125rem;
  margin-top: 16px;
  cursor: pointer;
  a {
    font-size: 0.875rem;
    padding-left: 4px;
  }
}
.interest-card:hover {
  background: var(--ion-color-lobos-blue);
  color: var(--ion-color-lobos-text-white);
  border: 1px solid var(--ion-color-lobos-blue);
  box-shadow: none;
  h1,
  p {
    color: var(--ion-color-lobos-text-white);
  }
}
.home-categories-container {
  top: 64px;
}
.link-gallery-slider {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 16px;
  text-align: end;
}

.home-page-masonry-container {
  margin-top: 40px;
}

.modaltest {
  --color-checked: var(--ion-color-lobos-text-black);
  --color: var(--ion-color-lobos-blue);
  opacity: 1;
  text-transform: none;

  &_checked {
    font-weight: 700;
    // background-color:rgba(var(--ion-color-lobos-text-white),0.8);
    opacity: 1;
  }
  &_no_checked {
    background-color: var(--ion-color-lobos-light-background);

    // background-color:rgba(var(--ion-color-lobos-text-white),0.8);
    opacity: 0.5;
  }
  &_body_checked {
    // background-color:rgba(var(--ion-color-lobos-text-white),0.8);
    opacity: 1;
  }
}
.modal-poi {
  --min-width: 100%;
  --border-radius: 24px;
}
.modal-test {
  --min-width: 100%;
}

.modal-info-header {
  margin-top: 16px;
  box-shadow: none;
  border-radius: 26px;
  ion-icon {
    color: rgb(243, 213, 43);
    font-size: 1.5rem;
  }
  p {
    margin-left: 8px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 1.375rem;
    color: black;
  }
}
.info-text-modal {
  padding: 32px;
}

//--------------------- ICON CARD INFO ------------------------------

.icon-card-interest {
  top: 32px;
  box-shadow: none;
  ion-icon,
  h3 {
    color: var(--ion-color-lobos-blue);
  }
  ion-icon {
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }
  h3,
  p {
    margin-left: 16px;
  }
}

//---------------------Information Page ------------------------------
.information-container,
.faqs-container {
  background-color: var(--ion-color-lobos-blue);
  padding: 0;
  margin: 0;
}
.information-content,
.faqs-content {
  background-color: white;
  border-top-right-radius: 32px;
  min-height: 800px;
}
.faqs-title {
  margin-bottom: 20px;
}
//----------------- Visiting Shift Form ------------------------------

.submit-button-shift-form {
  --background: var(--ion-color-lobos-primary);
  --color: white;
}

.logout-button-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout-button {
  --background: none;
}

// ----------------- Maintenance config ------------------------------
ion-col.maintenance-configuration {
  color: var(--ion-color-lobos-text-white);
  ion-row {
    padding: 8px 16px;
    ion-label {
      margin-right: 8px;
    }
  }
  ion-button {
    margin: 0px 16px;
  }
}

// ----------------- Group application ------------------------------
ion-grid {
  &.admin-dashboard {
    ion-button {
      margin-left: 16px;
    }
  }

  &.group-application {
    padding: 16px;
    ion-button {
      ion-icon {
        margin-right: 8px;
      }
    }
  }
}

ion-button.contact-button {
  --background: var(--ion-color-lobos-secondary);
  --color: var(--ion-color-lobos-primary);
}

ion-button.selected-language {
  color: var(--ion-color-lobos-secondary);
}
