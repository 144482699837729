@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Roboto&display=swap");
@import "./reset.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 8px;
  user-select: none;

  &.white-text {
    color: white;
  }
}

h1 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--ion-color-lobos-primary);
}

h2 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--ion-color-lobos-primary);

  &.img-text {
    color: var(--ion-color-lobos-text-white);
  }
}

h3 {
  font-family: "Roboto";
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 0px;
  color: var(--ion-color-lobos-text-black);

  &.img-text {
    color: var(--ion-color-lobos-text-white);
  }

  &.white {
    color: white;
  }
}

h4 {
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
  color: var(--ion-color-lobos-secondary);
  text-transform: uppercase;
}

h5 {
  font-family: "Roboto";
  color: var(--ion-color-lobos-primary);
  margin-bottom: 0px;
  font-size: 0.875rem;
  font-weight: 400;
}

h6 {
  font-family: "Roboto";
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 8px;
  color: var(--ion-color-lobos-secondary);
  // text-transform: uppercase;
}

p {
  font-family: "Roboto";
  color: var(--ion-color-lobos-text-grey);
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;

  strong,
  b {
    color: var(--ion-color-lobos-text-black);
    font-weight: 700;
  }
}

a {
  color: var(--ion-color-lobos-secondary);
}
