
//----------------------------  MENU ANIMATION


  
  $lightBlue:#EBFBFF;
  $darkBlue: #1539CF;
  $blue-lobos: #0064AA;


  
  /* MAIN VARIABLES FOR CUSTOMIZATION */
  $numOfItems: 4;
  $navAT: 1s;
  $textDelayStep: 0.1s;
  
  
  
  /* -------------------------------- */
  
  $font: Helvetica, Arial, sans-serif;
  
  $btnSize: 50px;
  $lineW: 28px;
  $lineH: 4px;
  $linesGap: 10px;
  $linesTotalH: $lineH*2 + $linesGap;
  $btnHorPad: ($btnSize - $lineW) / 2;
  $btnVertPad: ($btnSize - $linesTotalH) / 2;
  
  
  $itemsLeftPad: 20px;
  $itemsRightPad: $itemsLeftPad + $btnSize;
  $itemWidth: 70px;
  $navItemsWidth: 304px;  
  $textAT: $navAT - $textDelayStep * ($numOfItems - 1);
  
  $textFadeAT: 0.2s;
  $cub: cubic-bezier(.48,.43,.29,1.3);
  $backCub: cubic-bezier(0.49,-0.3,.68,1.23);
  $textCub: cubic-bezier(.48,.43,.7,2.5);
  



  .nav {
    height: $btnSize;
    border-radius: 5px;
    box-shadow: 0 10px 35px rgba(0,0,0,0.2);
  
    &__cb {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      pointer-events: none;
    }
  
    &__content {

      display: flex;
      flex-direction: column;
      position: absolute;

      right: 0;
      width: $btnSize;
      transition: width $navAT $backCub, background-color $navAT $cub;

      .nav__cb:checked ~ & {
        background-color: var(--ion-color-lobos-blue); 
        transition: width $navAT $cub;
        width: $navItemsWidth;

      }
    }
  
    &__items {
      transition: background-color $navAT $cub;

      width: $navItemsWidth;
      height: auto;
   
      list-style-type: none;
      font-size: 0;
    
    }
  
    &__item {
      
      vertical-align: top;
      width: 100%;
      line-height: $btnSize;
     
      perspective: 1000px;
      transition: color 0.3s;
      cursor: pointer;
  
      // &:hover {
      //   color: $darkBlue;
      // }
  
      &-text {
   
        display: block;
        height: 100%;
        transform: rotateY(-30deg);
        opacity: 0;
        transition: transform $textAT $textCub, opacity $textAT;
  
        .nav__cb:checked ~ .nav__content & {
          transform: rotateY(0);
          opacity: 1;
          transition: transform $textAT $textCub, opacity $textFadeAT;
          display: flex;
          align-items: center;
        }
  
        @for $i from 1 through $numOfItems {
          .nav__item:nth-child(#{$i}) & {
            transition-delay: ($numOfItems - $i) * $textDelayStep;
          }
  
          .nav__cb:checked ~ .nav__content .nav__item:nth-child(#{$i}) & {
            transition-delay: ($i - 1) * $textDelayStep;
          }
        }
      }
    }
  
    &__btn {
      // position: absolute;
      // right: 0;
      // top: 0;
      width: $btnSize;
      height: $btnSize;
      padding: $btnVertPad $btnHorPad;
      cursor: pointer;
      color: white;

      &:before,
      &:after {
        content: "";
        display: block;
        width: $lineW;
        height: $lineH;
        border-radius: 2px;
        background: white;
        transform-origin: 50% 50%;
        transition: transform $navAT $cub, background-color 0.3s;
      }
  
      &:before {
        margin-bottom: $linesGap;
      }
  
  
      .nav__cb:checked ~ & {
  
        &:before {
          background:var(--ion-color-lobos-text-white);
          transform: translateY($linesGap/2 + $lineH/2) rotate(-225deg);
        }
  
        &:after {
          background:var(--ion-color-lobos-text-white);
          transform: translateY($linesGap/-2 + $lineH/-2) rotate(225deg);
          
        }
      }
    }
  }

 .log-button{
 position: absolute;
 bottom: 0px;
 width: 100%;
  opacity: 0;
  transition: transform $textAT $textCub, opacity $textAT;

  .nav__cb:checked ~ .nav__content & {
    opacity: 1;
    transition: transform $textAT $textCub, opacity $textFadeAT;
  }
 }


 .custom-list{
  transition:  background-color $navAT $cub;
  background-color: var(--ion-color-lobos-blue); 
  transition: background-color $navAT $cub;
  opacity: 0;

  .nav__cb:checked ~ .nav__content & {
    opacity: 1;
    background-color: var(--ion-color-lobos-blue); 
  }
}

.custom-accordion-group{
  opacity: 0;
  .nav__cb:checked ~ .nav__content & {
    opacity: 1;
  }
}

.first-item {
  height: 65px;
// padding-left: 16px;
}

.nav__cb:not(:checked) ~ .nav__content {
  pointer-events: none; /* Deshabilita la interacción con los elementos */
  user-select: none; /* Evita la selección de texto en los elementos */

}

.buttons-menu{
  border-bottom-left-radius: 40px;
  background: $blue-lobos;
}
