.dashboard-first-row{
    border-top-right-radius: 32px;
    background-color: var(--ion-color-lobos-primary);
    margin-top: 16px;
}

.dashboard-card {
  border-radius: 16px;
}

.dashboard-row {
  gap: 16px;
}

.dashboard-card {
  box-shadow: 0px 5px 10px rgba(22, 27, 29, 0.23);
}

ion-row {
  &.subsection {
    gap: 16px;
    margin-top: 16px;
    ion-card {
      padding: 40px 0;
    }
  }
}

.dashboard-card-icons {
  width: 60px;
  height: 60px;
}

.card-dashboard-content {
  display: flex;
  justify-content: center;
}

.dashboard-col-card {
  --height: 500px;
}

.gap {
  gap: 20px;
}
