* {
  padding: 0;
  margin: 0;
}

ion-content {
  background: white;

  &.blue {
    background: var(--ion-color-lobos-primary);
  }

  &.lobos-background-base {
    background: var(--ion-color-lobos-primary);
  }
}

ion-content.maintenance {
  --padding-top: 10%;
  // --padding-start: 100px;
  // --padding-end: 100px;
  --padding-bottom: 100px;

  @media (max-width: 768px) {
    --padding-top: 15%; /* Padding superior para dispositivos móviles */
  }

  @media (max-width: 435px) {
    --padding-top: 20%; /* Padding superior para dispositivos móviles */
  }

  h2,
  h3,
  p {
    font-family: "Roboto";
    color: var(--ion-color-lobos-text-white);
    margin-bottom: 0px;
  }

  h2 {
    font-size: 64px;
    font-weight: bold;
    line-height: 1;
  }

  h3 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  p {
    font-size: 24px;
    line-height: 1.5;

    &#timer {
      margin-top: 32px;
    }
  }

  color: var(--ion-color-lobos-text-white);
  background-image: url("../../public/assets/result.jpg") no-repeat fixed;
  background-image: linear-gradient(rgba(7, 21, 38, 0), rgba(7, 21, 38, 0.65)),
    url("../../public/assets/result.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: 25% 50%;
  z-index: 1;
  overflow: hidden;

  ion-row {
    height: calc(100vh - 48px);
  }
}

ion-button {
  text-transform: none;
  --color: var(--ion-color-lobos-primary);
  --padding-start: 32px;
  --padding-end: 32px;
  --background: var(--ion-color-lobos-secondary);
  --border-radius: 32px;
  --box-shadow: none;
  margin-top: 16px;

  &.large {
    width: 70%;
  }

  &.footer {
    margin-top: 0;
  }

  &.text-gray {
    --color: var(--ion-color-lobos-text-grey);
  }

  &.white {
    --color: white;
    --background: transparent;
  }

  &[disabled] {
    &.counter {
      opacity: 1;
      --background: white;
    }

    &.custom-footer {
      opacity: 1;
      --background: var(--ion-color-lobos-secondary);
    }
  }
}

ion-title {
  &.custom {
    font-weight: 600;
  }
}

ion-card {
  --background: white;
  box-shadow: 0px 9px 27px -4px rgba(0, 0, 0, 0.13);

  &.application {
    border: 2px solid white;
    border-radius: 8px;
  }

  ion-card-title {
    ion-text p {
      color: var(--ion-color-lobos-secondary);
    }
  }
}

ion-toggle {
  --handle-background: white;
  --handle-background-checked: var(--ion-color-lobos-primary);
  --track-background: var(--ion-color-lobos-light-background);
  --track-background-checked: var(--ion-color-lobos-light-background);
  p {
    margin-bottom: 0px;
    color: #999;
  }
}
.active-toggle p {
  color: var(--ion-color-lobos-primary);
}
.toggle-active .toggle-text {
  color: var(--ion-color-lobos-primary);
}

.toggle-inactive .toggle-text {
  color: #999;
}
ion-item {
  --inner-padding-bottom: 0;
  --inner-padding-top: 0;
  --background: transparent;

  &.authorization {
    height: 35px;
  }

  &.country-selector {
    border: 1px solid var(--ion-color-lobos-text-grey);
    border-radius: 4px;
    opacity: 0.5;
  }

  &.selected {
    background: var(--ion-color-lobos-primary);
    color: white;
  }

  &.visitor-details {
    height: 35px;
  }

  ion-icon {
    color: var(--ion-color-lobos-text-white);

    &.custom {
      color: var(--ion-color-lobos-primary);
    }
  }
}

ion-card-content {
  &.authorization {
    background: white;
    padding: 16px;
  }

  &.application {
    background: red;
  }
}

ion-chip {
  padding-inline: 10px;
  background: white;

  &.okay {
    --color: #10cf80;
    border: 2px solid #10cf80;
  }

  &.pending {
    --color: #f7c321;
    border: 2px solid #f7c321;
  }

  &.expired {
    --color: var(--ion-color-lobos-text-grey);
    border: 2px solid var(--ion-color-lobos-text-grey);
    opacity: 0.3;
  }

  &.no {
    --color: #f55753;
    border: 2px solid #f55753;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 600;
  margin-bottom: 16px;
  background: white;
  border-radius: 16px;
  padding: 16px 16px 32px;
  .custom {
    ion-input {
      --color: #999;
      --background: white;
      --highlight-color-valid: var(--ion-color-lobos-secondary);
      --highlight-color-focused: var(--ion-color-lobos-secondary);
      margin-top: 16px;
      --border-color: #777;
      --inner-border-width: 1px;
    }
  }
}

ion-input {
  &.custom {
    --color: #999;
    --background: white;
    --highlight-color-valid: var(--ion-color-lobos-secondary);
    --highlight-color-focused: var(--ion-color-lobos-secondary);
    margin-top: 16px;
    --border-color: #777;
    --inner-border-width: 1px;
    &.input-date {
      --color: #999;
      font-weight: normal;
    }
  }
}
ion-select {
  &.custom {
    --color: #999;

    --background: white;
    --highlight-color-valid: var(--ion-color-lobos-secondary);
    --highlight-color-focused: var(--ion-color-lobos-secondary);
    margin-top: 16px;
  }
}

ion-textarea {
  --color: var(--ion-color-lobos-text-black);
  --highlight-color-valid: var(--ion-color-lobos-secondary);
  --highlight-color-focused: var(--ion-color-lobos-secondary);
  margin-top: 16px;
  height: 100px;

  &.custom {
    --color: #999;
    --background: white;
    --highlight-color-valid: var(--ion-color-lobos-secondary);
    --highlight-color-focused: var(--ion-color-lobos-secondary);
    margin-top: 16px;
  }
}

ion-modal {
  --border-radius: 8px;
  --max-width: calc(100% - 32px);
  --max-height: calc(100% - 32px);
}

ion-select {
  margin-top: 16px;
  color: #999;
}

ion-checkbox {
  margin-top: 16px;
}

ion-note {
  color: var(--ion-color-lobos-text-grey);
}

ion-menu {
  border-inline: 0px;

  ion-toolbar {
    --min-height: auto;
    --border-style: none !important;
    border: none;
    box-shadow: none;
  }
}

ion-toolbar {
  &.custom {
    padding-inline: 16px;
  }
}

ion-header {
  ion-toolbar {
    padding: auto;
  }
}

ion-label {
  &.current-date {
    font-size: 18px;
  }
}

ion-list {
  &.list-md {
    padding: 0px;
  }
}

ion-checkbox {
  --checkbox-background-checked: var(--ion-color-lobos-primary);
}

ion-checkbox::part(container) {
  border-radius: 4px;
  border: 2px solid var(--ion-color-lobos-primary);
}

ion-searchbar {
  &.md {
    // --background: rgb(255, 255, 255);
    padding: 0px;
  }

  &.custom {
    .searchbar-input.sc-ion-searchbar-md {
      &.placeholder {
        color: white;
      }

      color: var(--ion-color-lobos-text-grey);
      padding-left: 45px;
      padding-right: 20px;
      margin-top: 16px;
      box-shadow: none;
      border: 0.5px solid #999999;
      border-radius: 4px;
      height: 56px;
    }

    .searchbar-clear-icon.sc-ion-searchbar-md.md {
      color: white;
    }

    .searchbar-search-icon.sc-ion-searchbar-md {
      color: var(--ion-color-lobos-text-grey);
      top: 45%;
    }
  }

  &.home {
    .searchbar-input.sc-ion-searchbar-md {
      background: var(--ion-color-lobos-tertiary);
      color: rgba(white, 1);
      height: 16px;
      border-radius: 8px;

      &.placeholder {
        color: white;
      }
    }

    .searchbar-clear-icon.sc-ion-searchbar-md.md {
      color: white;
    }

    .searchbar-search-icon.sc-ion-searchbar-md {
      color: white;
      left: 0px;
      top: -2px;
    }
  }
}

ion-segment-button {
  --indicator-color: none;
  --border-radius: none;
}

//ALERT

/// TOAST

ion-alert {
  --background: var(--ion-color-lobos-light-background) !important;
}

ion-alert .alert-wrapper {
  border-radius: 16px !important;
  box-shadow: 0px 9px 27px -4px rgba(0, 0, 0, 0.13) !important;
}

ion-backdrop {
  --backdrop-opacity: 0.5;
}

ion-alert.custom-class {
  .alert-title {
    color: var(--ion-color-lobos-primary);
  }
  .alert-sub-title {
    margin-top: 16px;
    line-height: 1.5;
    color: var(--ion-color-lobos-text-black);
  }
  .alert-message {
    color: var(--ion-color-lobos-text-black);
  }
  .alert-button {
    color: var(--ion-color-lobos-primary);
  }

  .alert-input-group {
    padding: 12px 16px 20px;
  }
  .alert-input {
    border: 1px solid #999999;
    border-radius: 8px;
    padding-inline: 8px;
    --color: #999999;
    --background: white;
    --highlight-color-valid: var(--ion-color-lobos-secondary);
    --highlight-color-focused: var(--ion-color-lobos-secondary);
  }
  .alert-button-group {
    padding: 0px;
  }
  .alert-button {
    text-transform: initial;
    margin: 0px;
    padding-inline: 16px;
  }
  .alert-button-role-confirm {
    border-top-left-radius: 8px;
    background: var(--ion-color-lobos-primary);
    color: var(--ion-color-lobos-text-white);
  }
  .alert-button-role-cancel {
    color: var(--ion-color-lobos-primary);
  }
}

ion-loading.custom-class {
  --backdrop-opacity: 0.5;
  --spinner-color: var(--ion-color-lobos-primary);
  color: var(--ion-color-lobos-primary);

  .loading-wrapper {
    border-radius: 16px;
  }
}

a {
  color: var(ion-color-lobos-secondary);
}

ion-tab-button {
  --color: var(--ion-color-lobos-text-grey);
  --color-selected: var(--ion-color-lobos-primary);
}
