/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #5260ff;
  --ion-color-secondary-rgb: 82, 96, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4854e0;
  --ion-color-secondary-tint: #6370ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-lobos-primary: #0064AA;
  --ion-color-lobos-primary-rgb: 0, 100, 170;
  --ion-color-lobos-primary-contrast: #ffffff;
  --ion-color-lobos-primary-contrast-rgb: 255, 255, 255;
  --ion-color-lobos-primary-shade: #005896;
  --ion-color-lobos-primary-tint: #1a74b3;

  --ion-color-lobos-secondary: #00cfe3;
  --ion-color-lobos-secondary-rgb: 0, 207, 227;
  --ion-color-lobos-secondary-contrast: #000000;
  --ion-color-lobos-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-secondary-shade: #00b6c8;
  --ion-color-lobos-secondary-tint: #1ad4e6;

  --ion-color-lobos-tertiary: #150448;
  --ion-color-lobos-tertiary-rgb: 21, 4, 72;
  --ion-color-lobos-tertiary-contrast: #ffffff;
  --ion-color-lobos-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-lobos-tertiary-shade: #12043f;
  --ion-color-lobos-tertiary-tint: #2c1d5a;

  /* --ion-color-lobos-tertiary: #144188;
  --ion-color-lobos-tertiary-rgb: 20, 65, 136;
  --ion-color-lobos-tertiary-contrast: #ffffff;
  --ion-color-lobos-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-lobos-tertiary-shade: #123978;
  --ion-color-lobos-tertiary-tint: #2c5494; */

  --ion-color-lobos-quaternary: #28bdd1;
  --ion-color-lobos-quaternary-rgb: 40, 189, 209;
  --ion-color-lobos-quaternary-contrast: #000000;
  --ion-color-lobos-quaternary-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-quaternary-shade: #23a6b8;
  --ion-color-lobos-quaternary-tint: #3ec4d6;

  --ion-color-lobos-text-black: #1F1F1F;
  --ion-color-lobos-text-black-rgb: 31, 31, 31;
  --ion-color-lobos-text-black-contrast: #ffffff;
  --ion-color-lobos-text-black-contrast-rgb: 255, 255, 255;
  --ion-color-lobos-text-black-shade: #1b1b1b;
  --ion-color-lobos-text-black-tint: #353535;

  --ion-color-lobos-text-grey: #777777;
  --ion-color-lobos-text-grey-rgb: 119, 119, 119;
  --ion-color-lobos-text-grey-contrast: #ffffff;
  --ion-color-lobos-text-grey-contrast-rgb: 255, 255, 255;
  --ion-color-lobos-text-grey-shade: #696969;
  --ion-color-lobos-text-grey-tint: #858585;

  --ion-color-lobos-text-white: #F0F0F5;
  --ion-color-lobos-text-white-rgb: 240, 240, 245;
  --ion-color-lobos-text-white-contrast: #000000;
  --ion-color-lobos-text-white-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-text-white-shade: #d3d3d8;
  --ion-color-lobos-text-white-tint: #f2f2f6;

  --ion-color-lobos-background-body: #E6F0F7;
  --ion-color-lobos-background-body-rgb: 230, 240, 247;
  --ion-color-lobos-background-body-contrast: #000000;
  --ion-color-lobos-background-body-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-background-body-shade: #cad3d9;
  --ion-color-lobos-background-body-tint: #e9f2f8;

  --ion-color-lobos-light-background: #e7edf9;
  --ion-color-lobos-ligth-background-rgb: 231, 237, 249;
  --ion-color-lobos-ligth-background-contrast: #000000;
  --ion-color-lobos-ligth-background-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-ligth-background-shade: #cbd1db;
  --ion-color-lobos-ligth-background-tint: #e9effa;

  --ion-color-lobos-form: #e7edf9;
  --ion-color-lobos-form-rgb: 231, 237, 249;
  --ion-color-lobos-form-contrast: #000000;
  --ion-color-lobos-form-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-form-shade: #cbd1db;
  --ion-color-lobos-form-tint: #e9effa;

  --ion-color-lobos-blue: #0064AA;
  --ion-color-lobos-blue-rgb: 230, 240, 247;
  --ion-color-lobos-blue-contrast: #000000;
  --ion-color-lobos-blue-contrast-rgb: 0, 0, 0;
  --ion-color-lobos-blue-shade: #cad3d9;
  --ion-color-lobos-blue-tint: #e9f2f8;

  --ion-color-cabildo: #008996;
  --ion-color-cabildo-rgb: 0, 137, 150;
  --ion-color-cabildo-contrast: #ffffff;
  --ion-color-cabildo-contrast-rgb: 255, 255, 255;
  --ion-color-cabildo-shade: #007984;
  --ion-color-cabildo-tint: #1a95a1;

  --swiper-navigation-size: 22px;
}

.ion-color-lobos-blue {
  --ion-color-base: var(--ion-color-lobos-blue);
  /* --ion-color-base-rgb: var(--ion-color-lobos-primary-rgb);
  --ion-color-contrast: var(--ion-color-lobos-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-primary-shade);
  --ion-color-tint: var(--ion-color-lobos-primary-tint); */
}

.ion-color-lobos-primary {
  --ion-color-base: var(--ion-color-lobos-primary);
  --ion-color-base-rgb: var(--ion-color-lobos-primary-rgb);
  --ion-color-contrast: var(--ion-color-lobos-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-primary-shade);
  --ion-color-tint: var(--ion-color-lobos-primary-tint);
}

.ion-color-lobos-secondary {
  --ion-color-base: var(--ion-color-lobos-secondary);
  --ion-color-base-rgb: var(--ion-color-lobos-secondary-rgb);
  --ion-color-contrast: var(--ion-color-lobos-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-secondary-shade);
  --ion-color-tint: var(--ion-color-lobos-secondary-tint);
}

.ion-color-lobos-tertiary {
  --ion-color-base: var(--ion-color-lobos-tertiary);
  --ion-color-base-rgb: var(--ion-color-lobos-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-lobos-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-tertiary-shade);
  --ion-color-tint: var(--ion-color-lobos-tertiary-tint);
}

.ion-color-lobos-quaternary {
  --ion-color-base: var(--ion-color-lobos-quaternary);
  --ion-color-base-rgb: var(--ion-color-lobos-quaternary-rgb);
  --ion-color-contrast: var(--ion-color-lobos-quaternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-quaternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-quaternary-shade);
  --ion-color-tint: var(--ion-color-lobos-quaternary-tint);
}

.ion-color-lobos-background-body {
  --ion-color-base: var(--ion-color-lobos-background-body);
  --ion-color-base-rgb: var(--ion-color-lobos-background-body-rgb);
  --ion-color-contrast: var(--ion-color-lobos-background-body-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-background-body-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-background-body-shade);
  --ion-color-tint: var(--ion-color-lobos-background-body-tint);
}


.ion-color-lobos-text-black {
  --ion-color-base: var(--ion-color-lobos-text-black);
  --ion-color-base-rgb: var(--ion-color-lobos-text-black-rgb);
  --ion-color-contrast: var(--ion-color-lobos-text-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-text-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-text-black-shade);
  --ion-color-tint: var(--ion-color-lobos-text-black-tint);
}

.ion-color-lobos-text-grey {
  --ion-color-base: var(--ion-color-lobos-text-grey);
  --ion-color-base-rgb: var(--ion-color-lobos-text-grey-rgb);
  --ion-color-contrast: var(--ion-color-lobos-text-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-text-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-text-grey-shade);
  --ion-color-tint: var(--ion-color-lobos-text-grey-tint);
}

.ion-color-lobos-text-white {
  --ion-color-base: var(--ion-color-lobos-text-white);
  --ion-color-base-rgb: var(--ion-color-lobos-text-white-rgb);
  --ion-color-contrast: var(--ion-color-lobos-text-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-text-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-text-white-shade);
  --ion-color-tint: var(--ion-color-lobos-text-white-tint);
}

.ion-color-lobos-form {
  --ion-color-base: var(--ion-color-lobos-form);
  --ion-color-base-rgb: var(--ion-color-lobos-form-rgb);
  --ion-color-contrast: var(--ion-color-lobos-form-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-form-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-form-shade);
  --ion-color-tint: var(--ion-color-lobos-form-tint);
}

.ion-color-lobos-light-background {
  --ion-color-base: var(--ion-color-lobos-light-background);
  --ion-color-base-rgb: var(--ion-color-lobos-light-background-rgb);
  --ion-color-contrast: var(--ion-color-lobos-light-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lobos-light-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-lobos-light-background-shade);
  --ion-color-tint: var(--ion-color-lobos-light-background-tint);
}

.ion-color-cabildo {
  --ion-color-base: var(--ion-color-cabildo);
  --ion-color-base-rgb: var(--ion-color-cabildo-rgb);
  --ion-color-contrast: var(--ion-color-cabildo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cabildo-contrast-rgb);
  --ion-color-shade: var(--ion-color-cabildo-shade);
  --ion-color-tint: var(--ion-color-cabildo-tint);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
/* 
  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
/* 
  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  } */


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;

  } */

}