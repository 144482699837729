.first_row{
    display: flex;
    justify-content: center;
    padding-top: 32px;
}
.validation-search-input {
    margin-right: 16px;
    color: white;
}

.validation-manual-message {
    text-align: center;
    color: var(--ion-color-lobos-text-white);
}

.text-button-manual {
    color: var( --ion-color-lobos-primary);
}
.validation-search-form {
    padding-top: 32px;
}

.card-manual-validation{
    padding-top: 16px;
}

.manual-validation-card-header{
padding-left: 16px;
padding-top: 16px;
}

.manual-validation-card-p{
    padding-bottom: 4px;
}

.validation-manual-card{
    border-radius: 16px;
}

.manual-validation-card-accordion {
    ion-icon{
        color: var(--ion-color-lobos-primary);
    }
  }